import { NavLink } from "react-router-dom";
import Reveal from "./Reveal";

export default function Footer() {
    return (
        <Reveal>
            <footer className="w-full p-4 lg:p-8 xl:py-10 xl:px-0 xl:max-w-6xl mx-auto">
                <Reveal><hr className="border-[0.5px] border-slate-300 my-8"/></Reveal>
                <div className="flex flex-col-reverse gap-5 lg:gap-0 lg:flex-row items-center justify-between">
                    <p className="text-center text-sm">Copyright © 2023</p>
                    <ul className="flex flex-col lg:flex-row items-center lg:gap-4 text-sm">
                        <li>
                            <NavLink to="/mentions-legales">Mentions légales</NavLink>
                        </li>
                        <li>
                            <NavLink to="/politique-de-confidentialite">Politique de confidentialité</NavLink>
                        </li>
                        <li>
                            <NavLink to="/conditions-d-utilisation">Conditions d'utilisation</NavLink>
                        </li>
                    </ul>
                    <ul className="flex items-center gap-4">
                        <li>
                            <a href="https://www.linkedin.com/in/benjamin-drouin-3763371b6/" target="_blank" rel="noreferrer"><i className='bx bxl-linkedin text-2xl'></i></a>
                        </li>
                        <li>
                            <a href="https://github.com/LDDW" target="_blank" rel="noreferrer"><i className='bx bxl-github text-2xl'></i></a>
                        </li>
                    </ul>  
                </div>
            </footer>
        </Reveal>
    );
}