import { createBrowserRouter } from "react-router-dom";
import NotFoundPage from "./pages/NotFoundPage.jsx";
import LegalLayouts from "./layouts/LegalLayouts.jsx";
import ConditionOfUse from "./pages/ConditionOfUse.jsx";
import LegalNotices from "./pages/LegalNotices.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.jsx";
import Home from "./pages/Home.jsx";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/",
        element: <LegalLayouts />,
        children: [
            {
                path: "/conditions-d-utilisation",
                element: <ConditionOfUse />,
            }, 
            {
                path: "/mentions-legales",
                element: <LegalNotices />,
            }, 
            {
                path: "/politique-de-confidentialite",
                element: <PrivacyPolicy />,
            }, 
        ]
    },
    {
        path: "*",
        element: <NotFoundPage />,
    },
]);

export default router;