import { NavLink, Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import './../App.css';
import { useEffect } from "react";

export default function LegalLayouts() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="w-full p-4 lg:p-8 xl:py-10 xl:px-0 xl:max-w-6xl mx-auto">
                <NavLink to="/" className="flex items-center gap-4">
                    <i className='bx bx-arrow-back'></i>
                    <p>Retour au portfolio</p>
                </NavLink>
            </div>
            <section className="w-full p-4 lg:p-8 xl:py-10 xl:px-0 xl:max-w-6xl mx-auto legal">
                <Outlet />
            </section>
            <Footer />
        </>
    )
}