import { ScrollRestoration } from "react-router-dom";

export default function LegalNotices() {
    return (
        <>
            <ScrollRestoration />
            <h1>Mentions légales</h1>
            
            <h2>Éditeur du site et directeur de la publication</h2>
            <p>Nom de l'entreprise ou du propriétaire du site : Benjamin Drouin</p>
            <p>Email : contact@benjamin-drouin.fr</p>

            <h2>Hébergeur du site</h2>
            <p>Nom de l'hébergeur : Infomaniak Network SA</p>
            <p>Adresse : Rue Eugène Marziano 25, 1227 Les Acacias (GE)</p>

            <h2>Propriété intellectuelle</h2>
            <p>Tous les contenus présents sur ce site, y compris mais sans s'y limiter, les textes et les images sont la propriété de Benjamin Drouin. Toute utilisation non autorisée de ces contenus est strictement interdite.</p>

            <h2>Protection des données personnelles</h2>
            <p>Pour connaître la politique de confidentialité et de protection des données personnelles, veuillez consulter la <a href="/politique-de-confidentialite">politique de confidentialité</a>.</p>

            <h2>Cookies</h2>
            <p>Le site n'utilise pas de cookies.</p>

            <h2>Responsabilité</h2>
            <p>Je m'efforce de fournir des informations précises et à jour sur ce site, mais je ne peux pas garantir l'exactitude et l'exhaustivité de ces informations. Je décline toute responsabilité quant à l'utilisation que vous pourriez faire des informations présentes sur ce site.</p>

            <h2>Liens vers des sites tiers</h2>
            <p>Ce site peut contenir des liens vers des sites web de tiers. Ces liens sont fournis uniquement à titre informatif et je ne cautionne pas le contenu de ces sites tiers. Je décline toute responsabilité quant aux contenus, produits, services ou pratiques de ces sites tiers.</p>

            <h2>Contact</h2>
            <p>Pour toute question ou demande concernant les mentions légales, vous pouvez me contacter à l'adresse suivante : contact@benjamin-drouin.fr</p>
        </>
    )
}