import Footer from "../components/Footer";
import Reveal from "../components/Reveal";
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser';
import { useRef } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Contact() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        toast.info('Message en cours d\'envoi', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

        if(
            form.current.name.value !== null &&
            form.current.email.value !== null &&
            form.current.message.value !== null
        ){
            emailjs.sendForm('service_75j848o', 'template_ptqa72w', form.current, 'nF_RZjreoQBsa-JYp')
                .then((result) => {
                        form.current.reset();
                        toast.success('Votre message a été envoyé !', {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            });
                }, (error) => {
                        toast.error('Votre message n\'a pas été envoyé !', {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                });
        }
        
    };

    return (
        <section id="contact" className="relative">

            <ToastContainer />

            <div className="container lg:grid lg:grid-cols-2 gap-10">
                <div className="flex flex-col justify-center">
                    <Reveal><h2 className="mb-4 text-2xl lg:mb-8 lg:text-5xl font-bold">Contact</h2></Reveal>
                    <Reveal><p className="mb-4"> Si vous êtes intéressé par mon travail ou si vous avez des questions, n'hésitez pas à me contacter en utilisant le formulaire de contact.</p></Reveal>
                    <Reveal>
                        <ul className="flex items-center gap-4 mb-10">
                            <li>
                                <a href="https://www.linkedin.com/in/benjamin-drouin-3763371b6/" target="_blank" rel="noreferrer"><i className='bx bxl-linkedin text-2xl hover:text-blue transition-all'></i></a>
                            </li>
                            <li>
                                <a href="https://github.com/LDDW" target="_blank" rel="noreferrer"><i className='bx bxl-github text-2xl hover:text-blue transition-all'></i></a>
                            </li>
                        </ul> 
                    </Reveal>
                </div>
                <div>
                    <Reveal>
                        <form ref={form} onSubmit={sendEmail} className="bg-slate-100 dark:bg-dark col-span-1 rounded-lg p-7 shadow">
                            <Reveal>
                                <label htmlFor="name">Nom complet</label>
                                <input type="text" name="name" id="name" required placeholder="John Doe" className="w-full mb-5 p-2 px-3 rounded-lg mt-2 dark:bg-dark-light outline-none border-2 border-transparent focus:border-blue transition-all" />
                            </Reveal>

                            <Reveal>
                                <label htmlFor="name">Adresse mail</label>
                                <input type="email" name="email" id="email" required placeholder="JohnDoe@example.com" className="w-full mb-5 p-2 px-3 rounded-lg mt-2 dark:bg-dark-light outline-none border-2 border-transparent focus:border-blue transition-all" />
                            </Reveal>
                            
                            <Reveal>
                                <label htmlFor="name">Message</label>
                                <textarea name="message" id="message" required cols="30" rows="10" placeholder="Votre message" className="w-full p-2 px-3 rounded-lg mt-2 dark:bg-dark-light outline-none border-2 border-transparent focus:border-blue transition-all resize-none"></textarea>
                            </Reveal>

                            <Reveal><button className="bg-blue text-white px-4 py-2.5 mt-5 rounded-md transition-all blue_shadow" type="submit">Envoyer le message</button></Reveal>
                        </form>
                    </Reveal>
                </div>
            </div>
            <Footer />
            <motion.span 
                className="w-1/2 lg:w-1/3 h-full bg-[#00aaff] absolute top-0 -z-50 -skew-x-12"
                initial={{ opacity: 0, right: '-20rem'}}
                animate={{ opacity: 1 , right: '-8rem'}}
                transition={{ duration: 0.75, delay: 0.2, type: 'spring' }}
            ></motion.span>
        </section>
    );
}