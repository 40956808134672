import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Reveal from '../components/Reveal';
import { motion } from 'framer-motion';
import ProfilImg from '../img/profil.webp';

export default function About() {
    return (
        <section id="about" className="bg-slate-100 dark:bg-dark">
            <div className="container">

                <Reveal>
                <div className="bg-white dark:bg-dark-dark rounded-lg lg:grid lg:grid-cols-2 mb-20">
                    <div className="col-span-1 flex flex-col items-center justify-center pt-10 lg:pt-0">
                        <motion.div 
                            className='aspect-square w-1/2 bg-sky-200 rounded-full overflow-hidden'
                            initial={{ opacity: 0, scale: 0 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 1, delay: 0.2, type: 'spring' }}
                        >
                            <img src={ProfilImg} alt="Profil de Benjamin Drouin" className="w-full h-full" />
                        </motion.div>
                        <Reveal>
                            <div className='transition-all w-full'>
                                <p className='text-center my-4'>Benjamin | Développeur full-stack</p>
                                <ul className="flex items-center justify-center gap-4">
                                    <li>
                                        <a href="https://www.linkedin.com/in/benjamin-drouin-3763371b6/" target="_blank" rel="noreferrer"><i className='bx bxl-linkedin text-2xl hover:text-blue transition-all'></i></a>
                                    </li>
                                    <li>
                                        <a href="https://github.com/LDDW" target="_blank" rel="noreferrer"><i className='bx bxl-github text-2xl hover:text-blue transition-all'></i></a>
                                    </li>
                                </ul>
                            </div>
                        </Reveal>
                    </div>
                    <Reveal>
                        <div className="col-span-1 p-10">
                            <Reveal>
                                <h2 className="mb-5 text-xl font-semibold">À propos de moi</h2>
                            </Reveal>
                            <Reveal>
                                <p>Je m'appelle Benjamin Drouin et j’ai 21 ans. j'ai réalisé un BTS SIO ainsi qu’un Bachelor développeur web en alternance chez Add Espace en tant que développeur web. Mon objectif professionnel est de devenir un développeur full stack.</p>
                            </Reveal>
                            <br/>
                            <Reveal>
                                <p>En dehors de ma carrière, j’ai de nombreuses passions comme la musculation, la moto, le cinéma, la finance, l'apprentissage de nouvelles connaissances, la découverte des dernières technologies, la lecture et le développement personnel.</p>
                            </Reveal>
                            <br/>
                            <Reveal>
                                <p>Je suis déterminé, curieux et passionné. Je suis prêt à relever les défis qui se présentent à moi, que ce soit sur le plan professionnel ou personnel, et je suis convaincu que mon parcours me réserve encore de belles opportunités à explorer.</p>
                            </Reveal>
                        </div>
                    </Reveal>
                </div>
                </Reveal>


                {/* timeline */}

                <VerticalTimeline>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(0, 170, 255)' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(0, 170, 255)' }}
                        date="2022 - 2023"
                        dateClassName="text-gray-100"
                        iconStyle={{ background: 'rgb(0, 170, 255)', color: '#fff' }}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                            </svg>
                        }
                    >
                        <h3 className="vertical-timeline-element-title text-white">Alternance Développeur web</h3>
                        <h4 className="vertical-timeline-element-subtitle text-white">Berric, Morbihan</h4>
                        <p className='text-white'>
                        Création d'une application web pour l'entreprise ADD ESPACE
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2022 - 2023"
                        dateClassName="text-gray-900 dark:timeline:text-gray-100"
                        iconStyle={{ background: 'rgb(0, 170, 255)', color: '#fff' }}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                            </svg>
                        }
                    >
                        <h3 className="vertical-timeline-element-title text-gray-800">BACHELOR DÉVELOPPEUR WEB</h3>
                        <h4 className="vertical-timeline-element-subtitle text-gray-800">Vannes, Morbihan</h4>
                        <p className=' text-gray-800'>Réalisé à My Digital School. Obtention du titre concepteur développeur d'application (CDA)</p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2020 - 2022"
                        dateClassName="text-gray-900 dark:timeline:text-gray-100"
                        iconStyle={{ background: 'rgb(0, 170, 255)', color: '#fff' }}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                            </svg>
                        }
                    >
                        <h3 className="vertical-timeline-element-title text-gray-800">BTS SIO</h3>
                        <h4 className="vertical-timeline-element-subtitle text-gray-800">Redon, Ille-et-vilaine</h4>
                        <p className=' text-gray-800'>Le BTS SIO SLAM (Services Informatiques aux Organisations, option Solutions Logicielles et Applications Métiers)</p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="Janvier - Février 2022"
                        dateClassName="text-gray-900 dark:timeline:text-gray-100"
                        iconStyle={{ background: 'rgb(0, 170, 255)', color: '#fff' }}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                            </svg>
                        }
                    >
                        <h3 className="vertical-timeline-element-title text-gray-800">Stage - Développeur web</h3>
                        <h4 className="vertical-timeline-element-subtitle text-gray-800">Allaire, Morbihan</h4>
                        <p className=' text-gray-800'>Création d'une application web pour l'association AMON ESPORT.</p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="Juillet 2021"
                        dateClassName="text-gray-900 dark:timeline:text-gray-100"
                        iconStyle={{ background: 'rgb(0, 170, 255)', color: '#fff' }}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                            </svg>
                        }
                    >
                        <h3 className="vertical-timeline-element-title text-gray-800">Stage - Développeur web</h3>
                        <h4 className="vertical-timeline-element-subtitle text-gray-800">Redon, Ille-et-vilaine</h4>
                        <p className=' text-gray-800'>Découverte du quotidien d'un développeur web au sein d'une agence de création de site internet.</p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="Mai - Juin 2021"
                        dateClassName="text-gray-900 dark:timeline:text-gray-100"
                        iconStyle={{ background: 'rgb(0, 170, 255)', color: '#fff' }}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                            </svg>
                        }
                    >
                        <h3 className="vertical-timeline-element-title text-gray-800">Stage - Développeur web</h3>
                        <h4 className="vertical-timeline-element-subtitle text-gray-800">Pipriac, Ille-et-vilaine</h4>
                        <p className=' text-gray-800'>Création et mise en ligne d'un site web pour l'association UKERUN.</p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2020"
                        dateClassName="text-gray-900 dark:timeline:text-gray-100"
                        iconStyle={{ background: 'rgb(0, 170, 255)', color: '#fff' }}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                            </svg>
                        }
                    >
                        <h3 className="vertical-timeline-element-title text-gray-800">Formation web designer</h3>
                        <h4 className="vertical-timeline-element-subtitle text-gray-800">UDEMY</h4>
                        <p className=' text-gray-800'>Apprentissage des bases essentielles pour créer des web designs réussis.</p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2020"
                        dateClassName="text-gray-900 dark:timeline:text-gray-100"
                        iconStyle={{ background: 'rgb(0, 170, 255)', color: '#fff' }}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                            </svg>
                        }
                    >
                        <h3 className="vertical-timeline-element-title text-gray-800">Formation développeur web</h3>
                        <h4 className="vertical-timeline-element-subtitle text-gray-800">UDEMY</h4>
                        <p className=' text-gray-800'>Apprentissage des langages et outils de développement web : HTML, CSS, JS, jQuery, Bootstrap, PHP, MySQL, WordPress.</p>
                    </VerticalTimelineElement>
                    
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2018 - 2020"
                        dateClassName="text-gray-900 dark:timeline:text-gray-100"
                        iconStyle={{ background: 'rgb(0, 170, 255)', color: '#fff' }}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                            </svg>
                        }
                    >
                        <h3 className="vertical-timeline-element-title text-gray-800">BAC STMG</h3>
                        <h4 className="vertical-timeline-element-subtitle text-gray-800">Redon, Ille-et-vilaine</h4>
                        <p className=' text-gray-800'>Baccalauréat Sciences et Technologies du Management et de la Gestion (STMG) option Gestion et Finance.</p>
                    </VerticalTimelineElement>
                    
                </VerticalTimeline>

            </div>
        </section>
    );
}