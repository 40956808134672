import { useEffect, useRef, useState } from "react";

export default function Darkmode() {

    const [modal, setModal] = useState(false);
    const modalRef = useRef(null);
    const [darkMode, setDarkMode] = useState();

    useEffect(() => {
        // get dark mode from local storage
        if (localStorage.theme === 'dark') {
            document.documentElement.classList.add('dark');
            setDarkMode('dark');
        } else if (!('theme' in localStorage)) {
            setDarkMode('system');
            if(window.matchMedia('(prefers-color-scheme: dark)').matches){
                document.documentElement.classList.add('dark');
            }
        } else {
            document.documentElement.classList.remove('dark');
            setDarkMode('light');
        }
        
        // init function to toggle modal if user clicks outside of modal
        const handleClickOutside = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                setModal(false);
            }
        }
        // add event listener to document
        document.addEventListener('click', handleClickOutside);

    }, []);

    // set dark mode
    const toggleDarkMode = (appTheme) => {
        if(appTheme === 'system') {
            localStorage.removeItem('theme');
            setDarkMode('system');
            if(window.matchMedia('(prefers-color-scheme: dark)').matches) {
                document.documentElement.classList.add('dark');
            } else {
                document.documentElement.classList.remove('dark');
            }
        } else if (appTheme !== 'system') {
            localStorage.theme = appTheme;
            setDarkMode(appTheme);
            if(appTheme === 'dark') {
                document.documentElement.classList.add('dark');
            } else {
                document.documentElement.classList.remove('dark');
            }
        }
        setModal(false);
    }

    // toggle modal
    const toggleModal = () => {
        setModal(!modal);
    }

    return (
        <div className="relative" ref={modalRef}>

            {/* button for toggle modal on mobile */}
            <button onClick={toggleModal} className="lg:hidden w-full p-4 select-none rounded-lg transition-all">
                {darkMode === 'light' ? (
                    <span className="flex items-center gap-4">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" />
                        </svg>
                        <p>Mode claire</p>
                    </span>
                ) : darkMode === 'dark' ? (  
                    <span className="flex items-center gap-4">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z" />
                        </svg>
                        <p>Mode sombre</p>
                    </span>
                ) : darkMode === 'system' ? (
                    <span className="flex items-center gap-4">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                        </svg>
                        <p>Mode Système</p>
                    </span>
                ) : null}
            </button>

            {/* button for toggle modal on desktop */}
            <button onClick={toggleModal} className={`hidden lg:block p-2 text-gray-800 dark:text-white hover:bg-white hover:shadow-sm hover:dark:bg-dark-dark rounded-lg transition-all ${modal ? 'bg-white dark:bg-dark-dark shadow-sm' : null}`}>
                {darkMode === 'light' ? (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" />
                    </svg>
                ) : darkMode === 'dark' ? (  
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z" />
                    </svg>
                ) : darkMode === 'system' ? (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                    </svg>
                ) : null}
            </button>
            {/* modal */}
            <div className={`absolute top-14 lg:top-10 left-2 lg:left-auto lg:right-0 w-48 bg-white shadow-sm rounded-lg overflow-hidden dark:bg-dark-dark ${modal ? null : 'hidden'}`}>
                <div className="flex items-center p-2 gap-2 cursor-pointer hover:bg-slate-100 hover:dark:bg-dark transition-all" onClick={() => toggleDarkMode('light')}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" />
                    </svg>
                    <p className="text-sm">Mode Claire</p>
                </div>
                <div className="flex items-center p-2 gap-2 cursor-pointer hover:bg-slate-100 hover:dark:bg-dark transition-all" onClick={() => toggleDarkMode('dark')}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z" />
                    </svg>
                    <p className="text-sm">Mode Sombre</p>
                </div>
                <div className="flex items-center p-2 gap-2 cursor-pointer hover:bg-slate-100 hover:dark:bg-dark transition-all" onClick={() => toggleDarkMode('system')}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                    </svg>
                    <p className="text-sm">Mode Système</p>
                </div>
            </div>
        </div>
    );
}

