import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";

export default function Reveal({children}) {

    const ref  = useRef(null);
    const isInView = useInView(ref, {once : true});

    const mainControls = useAnimation();

    useEffect(() => {
        if(isInView) {
            mainControls.start('visible');
        }
    }, [mainControls, isInView]);

    return (
        <div ref={ref}>
            <motion.div
                variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 },
                }}
                initial="hidden"
                animate={mainControls}
                transition={{ 
                    duration: 0.75, 
                    delay: 0.2 ,
                    type: 'spring'
                }}
                >
                {children}
            </motion.div>
        </div>
    );
}