import { ScrollRestoration } from "react-router-dom";

export default function PrivacyPolicy() {
    return (
        <>
            <ScrollRestoration />
            <h1>Politique de confidentialité</h1>

            <p>Date de dernière mise à jour : 31 juillet 2023</p>

            <h2>Collecte des données personnelles</h2>
            <p>Je collecte certaines données personnelles que vous me fournissez volontairement lorsque vous utilisez mon site web. Ces données peuvent inclure, sans s'y limiter, votre nom et prénom, votre adresse e-mail, etc. Je ne collecte que les données nécessaires aux fins spécifiées dans cette politique de confidentialité.</p>

            <h2>Utilisation des données personnelles</h2>
            <p>J'utilise les données personnelles que vous me fournissez pour les finalités suivantes :</p>
            <ul>
                <li>Envoyer automatiquement un mail de confirmation de réception dès que je reçois votre message via le formulaire de contact.</li>
                <li>Répondre à votre message</li>
            </ul>

            <h2>Partage des données personnelles</h2>
            <p>Je ne partage pas vos données personnelles avec des tiers</p>

            <h2>Conservation des données personnelles</h2>
            <p>Je conserve vos données personnelles seulement pendant la période de notre échange.</p>

            <h2>Vos droits</h2>
            <p>Vous avez le droit de supprimer les données personnelles que je détiens vous concernant. Pour exercer ce droit, contactez-moi à l'adresse indiquée ci-dessous.</p>

            <h2>Modifications de la politique de confidentialité</h2>
            <p>Je me réserve le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera publiée sur cette page avec une nouvelle date de mise à jour. Votre utilisation continue du site web après toute modification signifie que vous acceptez les nouvelles conditions de la politique de confidentialité.</p>

            <h2>Contact</h2>
            <p>Pour toute question ou demande concernant la politique de confidentialité, vous pouvez me contacter à l'adresse suivante : contact@benjamin-drouin.fr</p>
        </>
    )
}