import { NavLink } from 'react-router-dom';
import NotFoundImg from '../img/404.webp';

export default function NotFoundPage() {
  return (
    <div className="flex flex-col items-center gap-10 justify-center h-screen w-full bg-gray-50">
      <img src={NotFoundImg} alt="" className="w-1/4" />
      <h1 className="text-2xl font-semibold">La page que vous cherchez n'existe pas.</h1>
      <NavLink to="/">Retourner à l'accueil</NavLink>
    </div>
  );
}