import Reveal from "../components/Reveal";

import HtmlImg from "../img/html.webp";
import CssImg from "../img/css.webp";
import BootstrapImg from "../img/bootstrap.webp";
import TailwindImg from "../img/tailwind.webp";
import JavascriptImg from "../img/javascript.webp";
import AngularImg from "../img/angular.webp";
import ReactImg from "../img/react.webp";
import VueImg from "../img/vue.webp";
import PhpImg from "../img/php.webp";
import SymfonyImg from "../img/symfony.webp";
import LaravelImg from "../img/laravel.webp";
import MysqlImg from "../img/mysql.webp";
import JavaImg from "../img/java.webp";

export default function Skill() {
    return (
        <section id="skills">
            <div className="container">
                <Reveal><h2 className="mb-10 text-2xl lg:mb-20 lg:text-5xl font-bold">Technologies</h2></Reveal>
                <div className="grid grid-cols-5 gap-4 lg:grid-cols-7 lg:gap-14">
                    <Reveal>
                        <img src={HtmlImg} alt="Logo du langage de programmation Html" className="col-span-1 aspect-square"/>
                    </Reveal>
                    <Reveal>
                        <img src={CssImg} alt="Logo du langage de programmation Css" className="col-span-1 aspect-square"/>
                    </Reveal>
                    <Reveal>
                        <img src={BootstrapImg} alt="Logo du framework Bootstrap" className="col-span-1 aspect-square"/>
                    </Reveal>
                    <Reveal>
                        <img src={TailwindImg} alt="Logo du framework Tailwind" className="col-span-1 aspect-square"/>
                    </Reveal>
                    <Reveal>
                        <img src={JavascriptImg} alt="Logo du langage de programmation Javascript" className="col-span-1 aspect-square"/>
                    </Reveal>
                    <Reveal>
                        <img src={AngularImg} alt="Logo du framework Angular " className="col-span-1 aspect-square"/>
                    </Reveal>
                    <Reveal>
                        <img src={ReactImg} alt="Logo du framework React " className="col-span-1 aspect-square"/>
                    </Reveal>
                    <Reveal>
                        <img src={VueImg} alt="Logo du framework Vue.js " className="col-span-1 aspect-square"/>
                    </Reveal>
                    <Reveal>
                        <img src={PhpImg} alt="Logo du langage de programmation Php" className="col-span-1 aspect-square"/>
                    </Reveal>
                    <Reveal>
                        <img src={SymfonyImg} alt="Logo du framework Symfony" className="col-span-1 aspect-square"/>
                    </Reveal>
                    <Reveal>
                        <img src={LaravelImg} alt="Logo du framework Laravel" className="col-span-1 aspect-square"/>
                    </Reveal>
                    <Reveal>
                        <img src={MysqlImg} alt="Logo du logiciel Mysql " className="col-span-1 aspect-square"/>
                    </Reveal>
                    <Reveal>
                        <img src={JavaImg} alt="Logo du langage de programmation Java" className="col-span-1 aspect-square"/>
                    </Reveal>
                </div>
            </div>
        </section>
    );
}