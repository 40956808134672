import TypeIt from "typeit-react";
import Reveal from "../components/Reveal";
import { motion } from "framer-motion";
import HeroImg from "../img/hero.webp"
import CV from "../files/CV-Benjamin-Drouin.pdf"

export default function Hero() {
    return (
        <section id="hero" className="relative min-h-screen flex items-center">

            <div className="container flex flex-col-reverse lg:grid lg:grid-cols-2 h-screen gap-4 items-center">
                {/* welcome */}
                <div className="col-span-1 text-center h-[50vh] lg:h-auto lg:text-left">
                    <Reveal>
                        <h1>
                            <TypeIt
                                className="text-2xl md:text-3xl lg:text-5xl font-bold w-1/2 dark:text-[#ccd6f6] text-gray-800"
                                options={{ loop: true, html: true }}
                                element={"h1"}
                                getBeforeInit={(instance) => {
                                    instance.type("Bonjour, je m'appelle Benjamin !").pause(750).delete(2).pause(500).type(". Je suis <span class='lg:text-blue'>développeur full-stack !</span>").pause(10000).delete()
                                    return instance;
                                }}
                            />
                        </h1>
                    </Reveal>
                    <Reveal>
                        <p className="my-5 text-xs md:text-base">Bienvenue sur mon portfolio. À travers ce site, je souhaite vous faire découvrir mon parcours, mes compétences et mes projets.</p>
                    </Reveal>
                    <Reveal>
                        <ul className="flex items-center justify-center gap-4 lg:justify-start">
                            <li>
                                <a href="https://www.linkedin.com/in/benjamin-drouin-3763371b6/" target="_blank" rel="noreferrer"><i className='bx bxl-linkedin text-3xl hover:text-blue transition-all'></i></a>
                            </li>
                            <li>
                                <a href="https://github.com/LDDW" target="_blank" rel="noreferrer"><i className='bx bxl-github text-3xl hover:text-blue transition-all'></i></a>
                            </li>
                        </ul>
                    </Reveal>
                    <Reveal>
                        <a href={CV} target='_blank' rel='noopener noreferrer'>
                            <button className="button">Voir mon cv</button>
                        </a>
                    </Reveal>
                </div>
                <div className="col-span-1 relative w-full h-[50vh] pt-14 flex items-center justify-center lg:h-auto lg:p-0 lg:justify-end">
                    <motion.div 
                        className='aspect-square w-3/4 md:w-3/5 lg:w-11/12'
                        initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.75, delay: 0.2, type: 'spring' }}
                    >
                        <img src={HeroImg} alt="Illustration 3D d'un développeur qui fait de la programmation sur son ordinateur" className="w-full h-full object-cover" />
                    </motion.div>
                </div>
            </div>

            {/* bg color element */}
            <motion.span 
                className="w-1/2 lg:w-1/3 h-screen bg-[#00aaff] absolute top-0 -z-50 -skew-x-12"
                initial={{ opacity: 0, right: '-20rem'}}
                animate={{ opacity: 1 , right: '-7rem'}}
                transition={{ duration: 0.75, delay: 0.2, type: 'spring' }}
            ></motion.span>
        </section>
    );
}