import { ScrollRestoration } from "react-router-dom";

export default function ConditionOfUse() {
    return (
        <>
            <ScrollRestoration />
            <h1>Conditions d'utilisation</h1>

            <p>Bienvenue sur <a href="/" className="underline text-blue">benjamin-drouin.fr</a> ! En utilisant mon site web, vous acceptez de vous conformer aux conditions d'utilisation énoncées ci-dessous. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser mon site.</p>
            
            <h2>Utilisation du site :</h2>
            <p>Vous pouvez utiliser mon site web uniquement à des fins légales et conformément aux présentes conditions d'utilisation. Vous acceptez de ne pas utiliser le site d'une manière qui pourrait porter atteinte aux droits de tiers ou violer les lois en vigueur.</p>
            
            <h2>Contenu du site :</h2>
            <p>Le contenu affiché sur <a href="/" className="underline text-blue">benjamin-drouin.fr</a>, y compris, mais sans s'y limiter, le texte et les images sont la propriété exclusive de <a href="/" className="underline text-blue">benjamin-drouin.fr</a> et est protégé par les lois sur le droit d'auteur et autres lois applicables. Vous ne pouvez pas reproduire, distribuer, modifier, transmettre, afficher ou vendre le contenu sans notre autorisation écrite préalable.</p>
            
            <h2>Liens vers des sites tiers :</h2>
            <p>Mon site peut contenir des liens vers des sites web de tiers. Ces liens sont fournis uniquement à titre informatif et je ne cautionne pas le contenu de ces sites tiers. Je décline toute responsabilité quant aux contenus, produits, services ou pratiques de ces sites tiers.</p>
            
            <h2>Protection des données :</h2>
            <p>Je respecte votre vie privée et je m'engage à protéger vos données personnelles conformément à la politique de confidentialité. En utilisant mon site, vous consentez à la collecte, l'utilisation de vos informations conformément à notre politique de confidentialité.</p>
            
            <h2>Limitation de responsabilité :</h2>
            <p>Je m'efforce de maintenir le site web à jour et accessible, mais je ne garantie pas que le site sera toujours exempt d'erreurs, de virus ou d'autres problèmes techniques. Vous utilisez mon site à vos propres risques, et je décline toute responsabilité quant aux dommages directs, indirects, spéciaux, consécutifs ou autres découlant de l'utilisation de mon site.</p>
            
            <h2>Modifications des conditions d'utilisation :</h2>
            <p>Je me réserve le droit de modifier ces conditions d'utilisation à tout moment sans préavis. Il est de votre responsabilité de consulter régulièrement cette page pour prendre connaissance des mises à jour. Votre utilisation continue du site après toute modification signifie que vous acceptez les nouvelles conditions d'utilisation.</p>
            
            <h2>Loi applicable et juridiction :</h2>
            <p>Ces conditions d'utilisation sont régies par les lois en vigueur en France. Tout litige découlant de l'utilisation du site sera soumis à la juridiction exclusive des tribunaux compétents.</p>
            
            <p>Date d'entrée en vigueur : 31 juillet 2023</p>
            <p>Ces conditions d'utilisation constituent l'accord complet entre vous et <a href="/" className="underline text-blue">benjamin-drouin.fr</a> concernant votre utilisation de mon site web.</p>
        </>
    )
}