import './../App.css';
// pages
import Hero from '../sections/Hero';
import About from '../sections/About';
import Skill from '../sections/Skill';
import Project from '../sections/Project';
import Contact from '../sections/Contact';
// components
import Header from '../components/Header';
import { ScrollRestoration } from 'react-router-dom';

export default function Home() {
  return (
    <>
      <ScrollRestoration />
      {/* header */}
      <Header />
      {/* sections */}
      <Hero />
      <About />
      <Skill />
      <Project />
      <Contact />
      {/* footer */}
      {/* <Footer /> */}
    </>
  );
}