import ProjectCard from "../components/ProjectCard";

import TodolistVueLaravel from "../img/mockup_todolist_vue_laravel.webp";
import PortfolioProjectImg from "../img/mockup_portfolio.webp";
import AddEspaceProjectImg from "../img/mockup_add_espace.webp";
import MonGrandFrereProjectImg from "../img/mockup_mongrandfrere.webp";

export default function Project() {
    return (
        <section id="projects" className="bg-slate-100 dark:bg-dark">
            <div className="container lg:grid lg:grid-cols-4 lg:gap-4">  

                <ProjectCard 
                    title="Todolist" 
                    desc="Ce projet Todolist a été une expérience formidable pour moi. J'ai pu découvrir les fondamentaux de Vue.js et me lancer dans le monde des API REST avec Laravel. Dans cette application, vous trouverez plusieurs fonctionnalités essentielles comme un système d'authentification, une gestion des todolists et des tâches."
                    projectLink="" 
                    githubLink="https://github.com/LDDW/todolist-vue-laravel" 
                    img={TodolistVueLaravel}   
                    alt="Mockup situé dans un espace de coworking avec un ordinateur fixe affichant le projet todolist"
                    tags={['Laravel', 'Vue', 'Tailwind']} 
                />

                <ProjectCard 
                    title="Portfolio" 
                    desc="Le projet portfolio est un résumé de mon parcours en tant que développeur en y présentant mes compétences, mes projets et mon parcours professionnel." 
                    projectLink="/" 
                    githubLink="" 
                    img={PortfolioProjectImg}
                    alt="Mockup situé dans un espace de coworking avec un ordinateur fixe affichant le portfolio"
                    tags={['React', 'Tailwind']} 
                />

                <ProjectCard 
                    title="Add espace" 
                    desc="Add Espace est une entreprise qui réalise des extensions d'habitations. Le projet a pour but de créer un CRM et un ERP pour l'entreprise. Ce projet contient plusieurs fonctionnalités optimisant l'efficacité de l'entreprise." 
                    projectLink="" 
                    githubLink="" 
                    img={AddEspaceProjectImg}
                    alt="Mockup situé dans un espace de coworking avec un ordinateur fixe affichant le projet add espace"
                    tags={['Laravel', 'Livewire', 'Alpine.js', 'Tailwind']} 
                />
                
                <ProjectCard 
                    title="Mon Grand Frère"
                    desc="Mon Grand Frère est une entreprise de coaching emploi. Le projet consistait à développé un site de vente de formations avec un tableau de bord administratif pour gérer efficacement le contenu et les activités du site." 
                    projectLink="https://mongrandfrere.com/" 
                    githubLink="https://github.com/LDDW/mongrandfrere" 
                    img={MonGrandFrereProjectImg}
                    alt="Mockup situé dans un espace de coworking avec un ordinateur fixe affichant le projet mon grand frère"
                    tags={['Laravel', 'Livewire', 'Alpine.js', 'Tailwind']} 
                />

            </div>
        </section>
    );
}