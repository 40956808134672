import Hamburger from 'hamburger-react'
import Darkmode from './Darkmode'
import { useEffect, useState } from 'react';

export default function Header() {

    const [isOpen, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen(!isOpen);
    }

    const activeLinkClass = (event) => {
      
        const links = document.querySelectorAll('.nav_list>li>a');
        links.forEach((link) => {
            link.classList.remove('navlink_active');
            link.classList.add('navlink_hover');
            if (event.target.getAttribute('href') === link.getAttribute('href')) {
                link.classList.remove('navlink_hover');
                link.classList.add('navlink_active');
            }
        });
      
        event.preventDefault();
        window.removeEventListener('scroll', scroll);
        
        const sectionId = event.target.getAttribute('href');
        const section = document.querySelector(sectionId);
        const sectionTop = section.offsetTop;

        window.scrollTo({
            top: sectionTop,
            behavior: 'smooth'
        });

        setTimeout(() => {
            window.addEventListener('scroll', scroll);
        }, 700);

    };

    useEffect(() => {
        window.addEventListener('scroll', scroll);
        window.addEventListener('scroll', () => {
            const navbar = document.querySelector('header');
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            const section = document.querySelector('#about');
            if(section){
                const sectionTop = section.offsetTop;
                if (scrollTop > sectionTop - 100) {
                    navbar.classList.add('bg-white','shadow','dark:bg-dark-dark');
                } else {
                    navbar.classList.remove('bg-white','shadow','dark:bg-dark-dark');
                }
            }
        });
        return () => {
            window.removeEventListener('scroll', scroll);
        };
    }, []);

    const scroll = () => {
        const sections = document.getElementsByTagName('section');
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        
        for (let i = 0; i < sections.length; i++) {
            let section = sections[i];
            let sectionTop = section.offsetTop;
            let sectionHeight = section.offsetHeight;
            let sectionId = section.getAttribute('id');
          
            if (scrollTop >= sectionTop && scrollTop < sectionTop + sectionHeight) {
                const links = document.querySelectorAll('.nav_list>li>a');
                links.forEach((link) => {
                    link.classList.remove('navlink_active');
                    link.classList.add('navlink_hover');
                    if (link.getAttribute('href') === `#${sectionId}`) {
                        link.classList.remove('navlink_hover');
                        link.classList.add('navlink_active');
                        setOpen(false);
                    }
                });
            }
        }
    };

    return (
        <header className='fixed top-0 left-0 right-auto bottom-auto w-full z-50 transition-all'>
            <nav>
                {/* laptop navbar */}
                <div className='flex items-center pl-4 pr-1 lg:px-8 justify-between h-16 lg:h-20'>
                    {/* logo */}
                    <div className="w-44">
                        <h1 className='font-bold text-lg'>BENJAMIN</h1>
                    </div>
                    <ul className='hidden lg:flex justify-between gap-8 nav_list'>
                        <li>
                            <a href="#hero" onClick={e => {activeLinkClass(e)}} className='navlink_desktop navlink_active'>Bienvenue</a>
                        </li>
                        <li>
                            <a href="#about" onClick={e => {activeLinkClass(e)}} className='navlink_desktop navlink_hover'>À propos</a>
                        </li>
                        <li>
                            <a href="#skills" onClick={e => {activeLinkClass(e)}} className='navlink_desktop navlink_hover'>Compétences</a>
                        </li>
                        <li>
                            <a href="#projects" onClick={e => {activeLinkClass(e)}} className='navlink_desktop navlink_hover'>Projets</a>
                        </li>
                        <li>
                            <a href="#contact" onClick={e => {activeLinkClass(e)}} className='navlink_desktop navlink_hover'>Contact</a>
                        </li>
                    </ul>
                    <div className="hidden lg:flex justify-end w-44">
                        <Darkmode />
                    </div>
                    {/* hamburger button */}
                    <div className="lg:hidden">
                        <Hamburger size={24} duration={0.2} toggled={isOpen} toggle={toggleOpen} />
                    </div>
                </div>

                {/* phone navbar */}
                <div className={`${isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'} transition-all fixed top-0 w-full h-full bg-slate-700/10 -z-10 lg:hidden`} >
                    <ul className='pt-16 bg-white dark:bg-dark'>
                        <li>
                            <a href="#hero" onClick={e => {activeLinkClass(e)}} className='navlink_mobile'>Bienvenue</a>
                        </li>
                        <li>
                            <a href="#about" onClick={e => {activeLinkClass(e)}} className='navlink_mobile'>À propos</a>
                        </li>
                        <li>
                            <a href="#skills" onClick={e => {activeLinkClass(e)}} className='navlink_mobile'>Compétences</a>
                        </li>
                        <li>
                            <a href="#projects" onClick={e => {activeLinkClass(e)}} className='navlink_mobile'>Projets</a>
                        </li>
                        <li>
                            <a href="#contact" onClick={e => {activeLinkClass(e)}} className='navlink_mobile'>Contact</a>
                        </li>
                        <li>
                            <Darkmode />
                        </li>
                    </ul>
                    
                </div>
            </nav>
        </header>
    );
}