import Reveal from "./Reveal";

export default function ProjectCard({title, desc, img, alt, tags, projectLink, githubLink}) {

    const tagsList = [
        {name: "Html", color: "bg-html-light text-html-dark"},
        {name: "Css", color: "bg-css-light text-css-dark"},
        {name: "Tailwind", color: "bg-tailwind-light text-tailwind-dark"},
        {name: "Bootstrap", color: "bg-bootstrap-light text-bootstrap-dark"},
        {name: "JavaScript", color: "bg-js-light text-js-dark"},
        {name: "Vue", color: "bg-vue-light text-vue-dark"},
        {name: "React", color: "bg-react-light text-react-dark"},
        {name: "Angular", color: "bg-angular-light text-angular-dark"},
        {name: "Alpine.js", color: "bg-alpine-light text-alpine-dark"},
        {name: "Php", color: "bg-php-light text-php-dark"},
        {name: "Symfony", color: "bg-symfony-light text-symfony-dark"},
        {name: "Laravel", color: "bg-laravel-light text-laravel-dark"},
        {name: "Livewire", color: "bg-livewire-light text-livewire-dark"},
        {name: "Java", color: "bg-java-light text-java-dark"},
        {name: "MySql", color: "bg-mysql-light text-mysql-dark"},
    ];

    return (
       <Reveal>
            <div className="col-span-5 sm:col-span-2 md:col-span-1 hover:bg-white hover:white_shadow hover:dark:bg-dark-light p-4 rounded-lg select-none transition-all">
                <Reveal>
                    <img src={img} alt={alt} className="rounded-lg relative w-full" />
                </Reveal>
                <div className="mt-4">
                    <Reveal>
                        <h2 className="text-lg font-bold truncate">{title}</h2>
                    </Reveal>
                    <Reveal>
                        <p className="text-sm">{desc}</p>
                    </Reveal>
                    
                    {/* tags of language of programmations */}
                    <Reveal>
                        <div className="flex gap-2 flex-wrap my-4">
                            {tags.map((tag, index) => {
                                const tagColor = tagsList.find(t => t.name === tag).color;
                                return (
                                    <span key={index} className={`px-2 py-1 rounded-lg text-xs ${tagColor}`}>{tag}</span>
                                );
                            })}
                        </div>
                    </Reveal>

                    <div className="flex gap-2">
                        { githubLink && githubLink !== "" &&
                            <Reveal>
                                <a href={githubLink} target="_blank" rel="noreferrer">
                                    <button className="w-10 h-10 flex items-center justify-center bg-[#ffaa00] dark:bg-[#e2aa38] orange_shadow rounded-lg transition-all">
                                        <i className='bx bx-code text-lg'></i>
                                    </button>
                                </a>
                            </Reveal>
                        }
                        { projectLink && projectLink !== "" &&
                            <Reveal>
                                <a href={projectLink} target="_blank" rel="noreferrer">
                                    <button className="p-2 flex items-center justify-center bg-blue blue_shadow text-gray-100 rounded-lg h-10 text-sm px-6 transition-all">
                                        Voir le projet
                                    </button>
                                </a>
                            </Reveal>
                        }
                    </div>

                </div>
            </div>
       </Reveal>
    );
}